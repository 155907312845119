.error {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 50px;
}

#find-form {
    .form-group-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
