.table {
    display: grid;
    grid-template-rows: 100% auto;
}

.table-actions-container {
    display: flex;

    button {
        margin-right: 25px;
    }

    input::placeholder {
        font-size: 1.4rem;
        padding-left: 10px;
        font-family: $body-font;
    }

    @include respond-below(xs) {
        flex-direction: column;

        .search-input {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}

.table-header > .table-item {
    font-weight: bold;
    border-bottom: 1px solid $dark-blue;

    svg {
        margin-left: 5px;
    }
}

.table-item {
    padding: 10px;
    text-align: left;
    font-size: 1.4rem;
    text-transform: capitalize;

    p {
        font-size: 1.4rem;
        margin-top: 0;
        margin-bottom: 5px;
    }

    a {
        // text-decoration: none;
        font-weight: bold;
        color: $dark-text;
    }
}

.total-item {
    font-weight: bold;
}

.table-item.email {
    text-transform: unset;
    word-wrap: break-word;
}

.table-row:nth-child(odd) {
    background-color: $light-gray;
}

