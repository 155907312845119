html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    box-sizing: border-box;
    overflow-x: hidden;
}

.app-container.loading {
    opacity: 0;
}

.view-container.loading {
    opacity: 0;
}

.view-container {
    opacity: 1;
    transition: opacity 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    overflow: scroll;
}

.app-container {
    opacity: 1;
    transition: all 0.5s ease;
}

.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
  
body {
    margin: 0;
    padding: 0;
    height: 100%;
    opacity: 1;
    transition: 1s opacity;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.half {
    width: 50%;

    @include respond-below(sm) {
        width: 100%;
    }
}

.half .flex-container {

    @include respond-below(md) {
        .half {
            width: 100%;
        }
    }
}


.full {
    width: 100%;
}

.forty-percent {
    width: 40%;

    @include respond-below(sm) {
        width: 100%;
    }
}

.sixty-percent {
    width: 60%;

    @include respond-below(sm) {
        width: 100%;
    }
}

.eighty-percent {
    width: 80%;
    max-width: 1000px;

    @include respond-below(sm) {
        width: 100%;
    }
}

.section {
    min-height: 60vh;
    background-color: white;
}

.section-small {
    min-height: 50vh;
}


button {
    cursor: pointer;
    padding: 10px 15px 10px 15px;
    border: none;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    height: 35px;
    background-color: $dark-blue;
    color: $light-text;
    box-shadow: $small-shadow;
}